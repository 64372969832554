
<!--
 * @Descripttion: 收藏的图标&动画
 * @version: 1.0.0
 * @Author: xup@imyfone.cn
 * @Date: 2022-08-15 14:02:13
 * @LastEditors: liujx@imyfone.cn
 * @
-->
<template>
  <p
    @click="handleClick(item)"
    :class="['common-icon collect-icon', params.is_collection === 2 ? 'hover-no-color' : '']"
  >
    <template v-if="!inCollection">
      <template  v-if="params.is_collection === 1">
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.22 8.42c-1.679-1.893-4.399-1.893-6.077 0C3.36 12.687 9.84 20.95 13.955 21c4.144.049 10.705-8.29 6.902-12.58-1.678-1.893-4.398-1.893-6.076 0l-.78.88-.782-.88z" stroke="#999" stroke-width="2" stroke-linecap="round"/></svg>
      </template>
      <template v-else>
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.22 8.42c-1.679-1.893-4.399-1.893-6.077 0C3.36 12.687 9.84 20.95 13.955 21c4.144.049 10.705-8.29 6.902-12.58-1.678-1.893-4.398-1.893-6.076 0l-.78.88-.782-.88z" fill="#F33" stroke="#F33" stroke-width="2" stroke-linecap="round"/></svg>
      </template>
    </template>
    <template v-else>
      <img
        :src="`${ossUrl}/sns/img/common/collect.gif?${timestamp}`"
      />
    </template>
    <span>{{params.collect_count === 0 ? '收藏' : count }}</span>
  </p>
</template>

<script setup>
import { reactive, computed, defineProps, defineEmits, toRefs, onBeforeMount } from 'vue'
import { dialogMsg, myToast } from '@/utils/dialogMsg.js'
import { feedsCollected } from '@/apis/blogs.js'
import mySocket from '@/mixin/socket.js'

const ossUrl = process.env.VUE_OSS_DOMIAN ? process.env.VUE_OSS_DOMIAN : 'https://static-test-sns.iappdata.com'

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  },
  type: {
    type: [String, Number],
    default: '1' // 1-动态 2-帖子 3-资讯
  },
  isCountShow: { // 点赞数量是否显示
    type: Boolean,
    default: true
  }
})

const emit = defineEmits(['handleClick'])

const state = reactive({
  params: computed(() => props.data),
  inCollection: false,
  timestamp: '',
  type: computed(() => Number(props.type)),
  time: null,
  count: computed(() => props.isCountShow ? state.params.collect_count : '已收藏') // 不显示收藏数量，则点击收藏后显示已收藏，否则显示收藏数量
})

const { params, inCollection, timestamp, count } = toRefs(state)
const { sendToSocket } = mySocket()
const handleClick = () => {
  state.timestamp = new Date().getTime()
  // eslint-disable-next-line no-trailing-spaces, camelcase
  const { id, user_id, is_collection } = state.params
  // 接口type和ws的obj_type 不同，需要转换处理
  // 收藏动态 type 1 obj_type 1
  // 收藏帖子 type 2 obj_type 3
  // 收藏资讯 type 3 obj_type 2

  feedsCollected({
    id: id,
    type: state.type,
    target_user: user_id
  })
    .then((res) => {
      if (res.code === 0) {
        // eslint-disable-next-line no-trailing-spaces, camelcase
        if (is_collection === 1) {
          state.inCollection = true
          state.time = setTimeout(() => {
            state.inCollection = false
          }, 1000)
        }

        // 收藏成功,发送websocket
        if (res.data.type === 1) {
          const socketList = []
          socketList.push(user_id)
          const wsType = state.type === 2 ? 3 : state.type === 3 ? 2 : 1
          sendToSocket(socketList, { id, obj_type: wsType, type: 3 })
        }
        emit('handleClick', state.params)
      } else if (res.code === 30007) {
        myToast({ type: 'warning', message: '内容已被删除' })
      } else {
        dialogMsg('error', res.msg)
      }
    }).catch((err) => {
      console.log(err)
    })
}

onBeforeMount(() => {
  clearTimeout(state.time)
})

</script>

<style scoped lang="less">
@import '../../assets/less/icon.less';

.collect-icon {
  &.hover-no-color svg {
    &:hover {
      background: transparent;
      path,
      rect {
        stroke: transparent;
      }
    }
  }
}

</style>
