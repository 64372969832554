<template>
  <p class="common-icon edit-icon" @click="handleClick">
    <svg  width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 7.548h-5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-5" stroke="#999" stroke-width="2" stroke-linecap="round"/><rect x="20.793" y="7.716" width="9.723" height="1" rx=".5" transform="rotate(135 20.793 7.716)" stroke="#999"/></svg>
    <template v-if="props.isTextShow">编辑</template>
  </p>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue'
const emit = defineEmits(['handleClick'])
const props = defineProps({
  isTextShow: { // 文案是否显示
    type: Boolean,
    default: true
  }
})
const handleClick = () => {
  emit('handleClick')
}
</script>

<style scoped lang="less">
@import '../../assets/less/icon.less';
</style>
