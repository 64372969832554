<template>
  <el-dialog :show-close="false" custom-class="scroll-bar like-dialog" width="596px" ref="likeDialogRef" :lock-scroll="false" :append-to-body="true">
    <template #title>
        <span :class="['cursor-pointer', (flag === 'like' || flag === 'forum') ? 'select' : 'normal']" @click="changeTab('like')">点赞</span>
        <span v-if="flag !== 'forum'" :class="['cursor-pointer', flag === 'read' ? 'select' : 'normal']" @click="changeTab('read')">已读</span>
    </template>
    <template v-if="(flag === 'like' || flag === 'forum') && likeUserList.length">
      <div class="user-wrapper" v-for="item in likeUserList" :key="item.id" v-show-tip>
        <UserAvatar :user="item.user" size="40" fontSize="14" medalSize="60"></UserAvatar>
        <el-tooltip :content="item.user.name" placement="bottom" effect="light">
          <div class="user-name">{{item.user.name}}</div>
        </el-tooltip>
      </div>
    </template>
    <template v-else-if="flag === 'read' && blogsRead.length && diaState !== 'forum'">
      <div class="user-wrapper" v-for="item in blogsRead" :key="item.id" v-show-tip>
          <UserAvatar :user="item.user" size="40" fontSize="14" medalSize="60"></UserAvatar>
          <el-tooltip :content="item.user.name" placement="bottom" effect="light">
            <div class="user-name">{{item.user.name}}</div>
          </el-tooltip>
        </div>
    </template>
    <template v-else>
      <div class="empty-like">还没有人{{ flag === 'read'? '已读' : '点赞' }}</div>
    </template>
  </el-dialog>
</template>

<script>
import { reactive, toRefs, watch, nextTick } from 'vue'
export default {
  emits: ['changeCountHandle'],
  props: {
    likeUserList: {
      type: Array
    },
    blogsRead: {
      type: Array
    },
    normalType: {
      type: String,
      default: 'like'
    },
    diaState: {
      type: String,
      default: 'blog'
    }
  },
  setup (props, { emit }) {
    const state = reactive({
      flag: '',
      likeDialogRef: null
    })
    // console.log(props.normalType)
    watch(() => props.normalType, (value) => {
      value && (state.flag = value)
    }, { immediate: true })

    watch(() => [props.likeUserList, props.blogsRead], async () => {
      await nextTick()
      resetScrollTop()
    })

    const changeTab = (type) => {
      state.flag = type
      emit('changeCountHandle', type)
    }

    const resetScrollTop = () => {
      const dialogBody = Array.prototype.find.call(state.likeDialogRef.dialogRef.children, i => i.className === 'el-dialog__body')
      if (dialogBody) {
        dialogBody.scrollTop = 0
      }
    }

    return {
      ...toRefs(state),
      changeTab
    }
  }
}
</script>

<style lang="less">
.like-dialog {
  height: 456px !important;
  border-radius: 6px;
  overflow: hidden;
  .el-dialog__header {
    height: 45px;
    line-height: 45px;
    padding: 0;
    padding-left: 20px;
    border: 1px solid #F1F1F1;
    & span {
      display: inline-block;
      width: 55px;
      text-align: center;
      font-weight: 700;
      font-size: 16px;
      margin-right: 20px;
      transform: translateY(-3px);
      border-radius: 2px;
    }
    .select {
      color: @active-text-color;
      border-bottom: 3px solid @active-text-color;
    }
    .normal {
      border-bottom: none;
      color: #2e2e2e;
    }
  }
  .el-dialog__body {
    height: 411px;
    padding: 32px;
    display: flex;
    align-content: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 5px;
        height: 6px;
    }
    &::-webkit-scrollbar-track {
        background: #fff;
        border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
        background: #e0e0e0;
        border-radius: 6px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #a8a8a8;
    }
    .user-wrapper {
      height: 74px;
      text-align: center;
      width: 40px;
      margin-right: 29px;
      .user-avatar {
        margin-right: 0;
      }
      .user-name {
        font-size: 12px;
        line-height: 16px;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 5px;
      }
      &:nth-child(8n) {
        margin-right: 0;
      }
    }
    .empty-like {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
<style scoped lang="less">

</style>
