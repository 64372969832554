<!--
 * @Description: 点赞列表
 * @Author: heqi
 * @Date: 2022-06-20 09:41:58
 * @LastEditTime: 2023-06-06 15:48:57
 * @LastEditors: tangjz
 * @FilePath: \sns_h5d:\hq\mfzj\src\components\LikeInfo.vue
-->
<template>
  <div class="view-list" v-if="blogReadCount>0">
    <span class="view-count cursor-pointer active-text-color" @click="showBlogsRead">{{blogReadCount}}</span>人已读
  </div>
  <div class="like-list" v-if="userList.length">
    <img src="@/assets/img/common/like.svg">
    <span class="like-user cursor-pointer" @click="toUserCenter(item.user.id)" v-for="(item, index) in userList" :key="item.id"><template v-if="index < 30"><span class="hover-user">{{item.user.name}}</span>{{(index + 1) === userList.length || index + 1  === 30  ? '' : '、'}}</template></span>{{userList.length > 30 ? '等' : '共'}}<span class="like-count active-text-color cursor-pointer" @click="showLike">{{userList.length}}人</span>觉得很赞
  </div>
</template>

<script>
import { computed, reactive, toRefs } from 'vue'
import { getLikeUserList } from '@/apis/journal.js'
import { getBlogsReadList } from '@/apis/blogs.js'
import { toUserCenter } from '@/utils/userCenter.js'

export default {
  name: 'LikeInfo',
  emits: ['showLike'],
  props: {
    searchId: {
      type: Number,
      default: NaN,
      required: true
    },
    searchType: { // 1-微博，2-论坛
      type: Number,
      default: NaN,
      required: true
    },
    blogReads: { // 阅读数
      type: Number
    }
  },
  setup (props, { emit }) {
    const state = reactive({
      params: {
        type: computed(() => props.searchType),
        id: props.searchId
      },
      userList: [], // 显示列表
      blogsReadList: [],
      blogReadCount: props.blogReads
    })

    const init = (init = true) => {
      return new Promise((resolve) => {
        getLikeUserList(state.params).then(({ data, code }) => {
          if (code === 0) {
            state.userList = data.like_list
            resolve(init ? data.like_list.length : data.like_list)
          }
        })
      })
    }

    const initLikeUser = () => {
      // 论坛-初始化获取点赞列表接口
      props.searchType === 2 && init()
    }
    initLikeUser()

    const showBlogsRead = (init = true) => {
      return new Promise((resolve) => {
        getBlogsReadList({ obj_type: state.params.type, obj_id: state.params.id }).then(({ data, code }) => {
          if (code === 0) {
            state.blogsReadList = data.data
            resolve(data.data)
            state.blogReadCount = state.blogsReadList.length
            init && emit('showLike', { userList: state.blogsReadList, id: state.params.id, type: 'read' })
          }
        })
      })
    }

    // 显示点赞列表
    const showLike = () => {
      emit('showLike', { userList: state.userList, id: state.params.id, type: 'like' })
    }

    return {
      ...toRefs(state),
      toUserCenter,
      init,
      showLike,
      showBlogsRead
    }
  }
}
</script>

<style lang="less" scoped>
.like-list, .view-list {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  padding-right: 56px;
  margin-top: 16px;
  word-break: break-all;
  color: @other-text-color;
  & > img {
    width: 14px;
    height: 14px;
    transform: translateY(2px);
    margin-right: 6px;
  }
  .like-count, .view-count {
    font-weight: 700;
  }

  .hover-user:hover {
    color: @active-text-color
  }
}
</style>
